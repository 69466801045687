import Layout from "components/layout";
import SEO from "components/seo";
import TranscriptionSrvices from "components/transcription-services";
import React from "react";

const index = () => {
  const title = "Accurate Transcription Services Made Easy";
  const description =
    "Accurate Transcripts Made Easy: Tomedes has automated the audio transcription process to make it simple and swift. Flawless accuracy at the best price is guaranteed. Transcription is simple, swift and efficient, secure and strictly confidential.";
  const keywords = "transcription services";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/transcription-services"
      />
      <TranscriptionSrvices />
    </Layout>
  );
};

export default index;
