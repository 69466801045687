import React from "react";

const SecondTransServices = () => {
  return (
    <div className="bg-darkBlue py-16">
      <h2 className="text-2xl md:text-5xl font-sans text-white text-center font-bold">
        Trusted by Business Customers Worldwide
      </h2>
      <ul className="pt-14 flex flex-col justify-center mx-auto gap-y-14 items-center px-4">
        <li className="pt-10">
          <img
            src="https://skins.tomedes.com/frontend/images/logos-video1.svg"
            alt=""
            className=" w-[50rem]"
          />
        </li>
        <li className="pt-10">
          <img
            src="https://skins.tomedes.com/frontend/images/logos-video2.svg"
            alt=""
            className=" w-[50rem]"
          />
        </li>
        <li className="pt-10">
          <img
            src="https://skins.tomedes.com/frontend/images/logos-video3.svg"
            alt=""
            className=" w-[50rem]"
          />
        </li>
      </ul>
      <div className="w-7/12 mx-auto pt-24">
        <p className="font-sans text-white text-[24px] text-center">
          Tomedes makes the audio-to-text transcription process simple, swift
          and efficient. The process is strictly professional and confidential,
          suitable for enterprises, small businesses and individuals. Our
          experts deliver fast turnaround and flawless results. We match prices.
          Your satisfaction is guaranteed.
        </p>
        <p className="font-sans text-white text-[24px] text-center pt-6">
          There are many varieties of audio and video transcription. We can
          transcribe audio to text with 100% accuracy, providing streamlined
          end-to-end online business processes and solutions combining
          automation and human expertise. On-time delivery guaranteed.
        </p>
      </div>
      <div className="w-10/12 mx-auto pt-12">
        <h2 className="text-[42px] font-sans text-white text-center">
          Transcription Solution
        </h2>
        <div className="flex flex-wrap pt-12 gap-5 justify-center">
          <div className="w-[335px]">
            <h4 className="text-white text-[24px] text-center font-semibold">
              UPLOAD:
            </h4>
            <p className="text-center text-[24px] text-white">
              Submit your audio or video files in any standard format.
            </p>
          </div>
          <div className="w-[335px]">
            <h4 className="text-white text-[24px] text-center font-semibold">
              PROCESS.
            </h4>
            <p className="text-center text-[24px] text-white">
              Your order is confirmed by email. Our transcribers execute their
              expertise.
            </p>
          </div>
          <div className="w-[335px]">
            <h4 className="text-white text-[24px] text-center font-semibold">
              DELIVERY.
            </h4>
            <p className="text-center text-[24px] text-white">
              We send back a transcription fast in your preferred format.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondTransServices;
