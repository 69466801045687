export const transcriptionServicesData = [
  {
    image:
      "https://skins.tomedes.com/frontend/images/tran-img/audio-services.svg",
    heading: "Audio Transcription",
    listData: [
      {
        text: "Audio transcription is the process of converting audio to text, synched to the media with a time stamp.",
      },
      {
        text: "Expert transcribers grasp the nuances of each word, with impeccable language comprehension, punctuation and spelling.",
      },
      {
        text: "Choose verbatim transcription, rendering each syllable, or instruct us to smooth the transcript so it flows as coherent,grammatically correct sentences.",
      },
      {
        text: "You can opt to translate transcripts too.",
      },
    ],
  },
  {
    image:
      "https://skins.tomedes.com/frontend/images/tran-img/video-services.svg",
    heading: "Video Transcription",
    listData: [
      {
        text: "Video transcription follows a process akin to that of audio transcription, except with some additional options, such as closed captioning and subtitling. (See descriptions of these processes below.)",
      },
      {
        text: "Opt whether to transcribe non-verbal language, such as signs or descriptions.",
      },
      {
        text: "You can also translate transcripts to additional languages.",
      },
      {
        text: "Transcreation adapts a transcript more creatively for optimal effect.",
      },
    ],
  },
  {
    image:
      "https://skins.tomedes.com/frontend/images/tran-img/marketing-translation-services.svg",
    heading: "Video Transcription",
    listData: [
      {
        text: "Closed captioning assumes an audience cannot hear the audio and needs a text description of what they would otherwise be hearing.",
      },
      {
        text: "This may include interpretive information about sound effects and other atmospherics, background music and its lyrics as well as other non-speech elements.",
      },
      {
        text: "This is useful for people with hearing difficulties or when a video may be watched without sound.",
      },
    ],
  },
  {
    image:
      "https://skins.tomedes.com/frontend/images/tran-img/website-loc-services.svg",
    heading: "Audio Transcription",
    listData: [
      {
        text: "We know subtitles from movies, television shows, and videos.",
      },
      {
        text: "Smart TVs and Netflix gives us huge control over media language.",
      },
      {
        text: "Subtitles assume an audience can hear the audio but will benefit from the dialogue provided in text form as well, especially in a different language.",
      },
      {
        text: "We deliver subtitles in your desired format, with time-stamps, ready for insertion into the video.",
      },
      {
        text: "Native-language expert transcribers grasp the nuances of each spoken word, possessing impeccable language comprehension, flawless punctuation and spelling.",
      },
    ],
  },
];
