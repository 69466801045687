import React, { useState } from "react";
import { useTranscriptionServices } from "hooks/Forms/useTranscriptionServices";
import { useForm } from "react-hook-form";
import Loader from "components/loader";
import ThankYouPopup from "components/popup/thankYouPopup";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import { Link } from "gatsby"
import { errorMessageEmail, patternEmail } from "utils/form-validation";

function FourthTransServices() {
  const [checkBox, setCheckBox] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoUpload, setVideoUpload] = useState("");

  // const location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { status, mutateAsync } = useTranscriptionServices();
  const { data } = useFetchLanguages();

  const onSubmit = (data) => {
    const formData = new FormData();
    // console.log(data)
    formData.append("url", data.videoUrl);
    formData.append("customer_email", data.email);
    formData.append("special_instructions", data.message);
    formData.append("translate_from", data.fromLang);
    formData.append("translate_to", data.toLang);
    formData.append("file", videoUpload);
    formData.append("phone_number", data.phoneNumber);
    formData.append("service", data.service);
    formData.append("consent", data.consent);

    // formData.append("translate_from", data.fromLang);
    // formData.append("translate_to", data.toLang);
    // formData.append("customer_email", data.email);
    // formData.append("phone_number", data.phoneNumber);
    // formData.append("audio_file", audioUpload);
    // formData.append("audio_url", data.audioUrl);
    // formData.append("special_instructions", data.message);

    // const postData = {
    //   name: data.name,
    //   business_email: data.email,
    //   description: data.message,
    //   translate_from: data.fromLang,
    //   translate_to: data.toLang,
    //   upload_file: imageupload,
    //   phone_number: data.phoneNumber,
    // };

    mutateAsync(formData).then((_) => {
      reset();
      setOpen(true);
    });
  };

  // const onFileUpload = () => {
  //   const formData = new FormData();
  //   formData.append("document", imageupload);
  //   mutateAsync(formData).then((response) => {
  //     if (response.success === 1) {
  //       setImageUpload("");
  //     }
  //   });
  // };

  const handleUploadImage = (e) => {
    const files = e.target.files[0];
    setVideoUpload(files);
  };
  return (
    <div
      className={`bg-midBlue bg-cover bg-no-repeat bg-center flex items-center justify-center py-20`}
      id="getStartedForm"
    >
      <div className="mainContainer w-full flex flex-wrap lg:flex-nowrap lg:w-9/12 xl:w-10/12 max-w-6xl items-center lg:justify-between justify-center text-white  lg:pl-6">
        {/* ------------Text Content Start------------ */}
        <div className="textContent text-white md:w-11/12 lg:w-[50%] w-full flex flex-col px-4 lg:px-0 ">
          <div className="font-sans">
            <h2 className="lg:text-40 text-3xl font-secondary font-bold mb-10 leading-8 md:leading-[52px]">
              Order Transcription Services
            </h2>
            <p className="text-24 leading-7">
              Transcribe audio and video recordings as text transcripts,
              subtitles and captions in over 100 languages.
            </p>
          </div>
        </div>

        <form
          className="form border-white bg-locationFormBorder py-8 lg:justify-between justify-center h-full lg:w-[45%] w-11/12 mt-4 ml-0 lg:ml-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ThankYouPopup open={open} setOpen={setOpen} />

          <div className="inputs flex flex-col items-center justify-center text-black ">
            <div className="w-full lg:ml-[10px]">
              <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6 ">
                <label htmlFor="" className="flex flex-col">
                  <span className="font-opensans text-xs font-semibold">
                    Translate from
                  </span>
                  <select
                    // className=" bg-white text-[#9e9e9e] py-[10px] px-4 pr-10 mt-4 rounded"
                    className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
                    {...register("fromLang")}
                    id="fromLang"
                    name="fromLang"
                  >
                    <option disabled >
                      Translate from
                    </option>
                    {data
                      ? data?.data?.map(({ source_language }) => (
                        <option className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded">
                          {source_language}
                        </option>
                      ))
                      : null}
                  </select>
                </label>
                <label htmlFor="" className="flex flex-col">
                  <span className="font-opensans text-xs font-semibold">
                    Translate to
                  </span>
                  <select
                    // className=" bg-white text-[#9e9e9e] py-[10px] px-4 pr-10 mt-4 rounded"
                    className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
                    {...register("toLang")}
                    id="toLang"
                    name="toLang"
                  >
                    <option disabled >
                      Translate to
                    </option>
                    {data
                      ? data?.data?.map(({ source_language }) => (
                        <option className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded">
                          {source_language}
                        </option>
                      ))
                      : null}
                  </select>
                </label>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6">
                <label className="flex flex-col">
                  <span className="font-opensans text-[12px] font-semibold">
                    Email Address*
                  </span>
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="border-borderGray font-opensans border rounded  lg:w-11/12 w-full pl-3  h-[34px] text-xs"
                    id="email"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern: patternEmail,
                    })}
                  />
                  {errors.email && (
                    <span className="text-red text-[12px] pt-0 pb-2">
                      {errorMessageEmail}
                    </span>
                  )}
                </label>

                <label className="flex flex-col">
                  <span className="font-opensans text-[12px] font-semibold">
                    Phone Number
                  </span>
                  <input
                    name="phoneNumber"
                    type="tel"
                    placeholder="Enter Your Number Here"
                    className="border-borderGray font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs  mb-6"
                    {...register("phoneNumber")}
                    id="phoneNumber"
                  />
                </label>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6 ">
                <label htmlFor="" className="flex flex-col">
                  <span className="font-opensans text-xs font-semibold">
                    Service
                  </span>
                  <select
                    className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] h-[34px] text-xs mb-6 min-h-[80px]"
                    {...register("service")}
                    id="service"
                    name="service"
                    multiple
                  >
                    <option disabled>Select Services</option>
                    <option>Transcribe</option>
                    <option>Time Sync</option>
                    <option>Translate</option>
                    <option>Closed caption</option>
                    <option>Subtitle</option>
                    <option>I'm not sure</option>
                  </select>
                </label>
                <label className="flex flex-col self-start">
                  <span className="font-opensans text-[12px] font-semibold">
                    Upload file / link
                  </span>
                  <input
                    name="videoUpload"
                    type="file"
                    placeholder="Share a link to"
                    className="border-borderGray font-opensans border rounded lg:w-11/12 w-full pl-3  h-[34px] text-xs mb-6"
                    {...register("videoUpload")}
                    id="videoUpload"
                    onChange={handleUploadImage}
                    value={videoUpload}
                  />
                </label>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-4 md:px6 ">
                <label className="flex flex-col">
                  <span className="font-opensans text-xs font-semibold">
                    Or Share a link
                  </span>
                  <input
                    type="text"
                    placeholder="Or Share a link to your video"
                    className="border-borderGray font-opensans border rounded  lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs  mb-6"
                    {...register("videoUrl")}
                    id="videoUrl"
                    name="videoUrl"
                  />
                </label>
              </div>
            </div>

            <textarea
              className="border-borderGray font-opensans border rounded  pl-3 w-[90%] text-xs py-2"
              cols="30"
              rows="4"
              placeholder="Special Instructions"
              {...register("message")}
              id="message"
              name="message"
            ></textarea>
            <div className="my-3">
              <div className="flex items-baseline gap-2">
                <input
                  type="checkbox"
                  name="consent"
                  id="consent"
                  {...register("consent", {
                  })}
                  onChange={() => setCheckBox((value) => !value)}
                />
                <p className="text-sm text-black font-opensans">
                  I agree to Tomedes'{" "}
                  <Link
                    to="/privacy"
                    className="text-orange hover:text-newOrange-100 underline transition-colors"
                  >
                    Privacy Policy.
                  </Link>{" "}
                  by submitting this form.
                </p>
              </div>
              {errors.privacy_policy && (
                <span className="text-red text-sm font-primary mb-[10px] mt-[-16px]">
                  Please tick above to agree terms & conditions
                </span>
              )}
            </div>
            <button
              href="/"
              className="bg-orange py-2 text-center font-secondary font-extrabold mt-6 text-white block w-11/12"
            // onClick={onFileUpload}
            >
              {status === "loading" ? <Loader /> : "SUBMIT"}{" "}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FourthTransServices;
