import React from "react";
import { transcriptionServicesData } from "./ThirdTransServicesConfig";

function ThirdTransServices() {
  return (
    <div className="bg-darkBlue pt-24 pb-20 px16 xl:px-32 font-sans">
      <div className="w-11/12 xl:w-10/12 max-w-6xl mx-auto">
        <h2 className="text-[42px] font-sans text-white text-center mb-16">
          Our Transcription Services
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7">
          {transcriptionServicesData &&
            transcriptionServicesData.map(({ image, heading, listData }, i) => {
              return (
                <div className="bg-black py-16 rounded-lg shadow-lg" key={i}>
                  <div className="relative mb-10 group">
                    <img
                      src={image}
                      alt=""
                      className="w-16 absolute left-5 bottom-0 group-hover:-translate-y-4 transition-transform duration-300"
                    />
                    <h4 className="text-white text-[24px] font-semibold bg-newBlueStripBg pl-24">
                      {heading}
                    </h4>
                  </div>
                  <ul className="text-[21px] text-white px-16 flex flex-col gap-4 font-light">
                    {listData.map(({ text }, i) => {
                      return (
                        <li
                          className="flex gap-5 items-baseline leading-6"
                          key={i}
                        >
                          <img
                            src="https://skins.tomedes.com/frontend/images/bluebullet.png"
                            alt="bullet"
                            className="w-2 h-2"
                          />
                          {text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ThirdTransServices;
