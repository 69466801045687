import React from "react";
import BannerTranslationScript from "./banner";
import SecondTransServices from "./secondTransServices";
import ThirdTransServices from "./ThirdTransServices";
import WhatChooseUs from "components/homepage/whyChooseUs";
import FourthTransServices from "./FourthTransServices";
import FifthTransServices from "./FifthTransServices";

const TranscriptionSrvices = () => {
  return (
    <>
      <BannerTranslationScript />
      <SecondTransServices />
      <ThirdTransServices />
      <FourthTransServices />
      <WhatChooseUs backgroundCol="bg-darkBlue" />
      <FifthTransServices />
    </>
  );
};

export default TranscriptionSrvices;
