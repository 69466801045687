import React from "react";
import { Link } from "gatsby";

function FifthTransServices() {
  return (
    <>
      <div className="bg-darkBlue pt-24 pb-20 px16 xl:px-32 font-sans text-white">
        <div className="w-11/12 xl:w-10/12 max-w-6xl mx-auto">
          <h2 className="text-[42px] font-sans text-white text-center mb-8">
            Areas of Transcription Expertise
          </h2>
          <p className="font-sans text-white text-[24px] text-center">
            Trusted by 95,000+ customers across all industries and domains
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 mt-10">
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/automotive.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">AUTOMOTIVE</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/bank-and-finance.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">BANKING & FINANCE</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/healthcare.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">HEALTH CARE</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/gaming.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">GAMING</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/technology.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">HIGH TECH</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/military.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">MILITARY</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/government.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">GOVERNMENT</h4>
            </div>
            <div className="flex flex-col items-center gap-3">
              <img
                src="https://skins.tomedes.com/frontend/images/transcription/legal.jpg"
                alt=""
              />
              <h4 className="text-[18px] uppercase">LEGAL SERVICES</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomButtons bg-black text-white">
        <div className="div flex flex-col sm:flex-row w-11/12 sm:w-8/12 lg:w-6/12 justify-between m-auto py-28">
          <div className="translateBtn text-center py-6">
            <h3 className="pb-[40px] font-sans text-white text-[24px]">
              Ready to get started?
            </h3>
            <Link
              to="#getStartedForm"
              className="bg-btnGreen rounded-full py-3 px-2 md:py-3 md:px-6 text-sm sm:text-base whitespace-nowrap uppercase text-[22px] font-bold"
            >
              Get Started
            </Link>
          </div>
          <div className="contactBtn text-center pb-6 py-6">
            <h3 className="pb-[40px] font-sans text-white text-[24px]">
              Want to tell us more about your needs?
            </h3>
            <Link
              to="/contactus.php"
              className=" border border-white rounded-full hover:bg-borderGray py-3 px-2 md:py-3 md:px-6 text-sm sm:text-base whitespace-nowrap uppercase text-[22px] font-bold"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="borderLine bg-grayBorder border-b"></div>
      </div>
    </>
  );
}

export default FifthTransServices;
